import { commonEnvironment } from './common.environment';

export const environment = {
  ...commonEnvironment,
  production: true,
  sentry: 'https://e7065c664f9d4a2dad99a4e0ef8c1acd@o296516.ingest.sentry.io/5479640',
  envName: 'prod',
  serverApiUrl: 'https://kallisto.ebl.ch',
  authServerUrl: 'https://auth.esds.ebl.ch/auth',
  bearerExcludedUrls: [],
  isServiceWorkerEnabled: true,
};

import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowser } from '@angular/platform-browser';
import { extendConfiguration } from './config';
import * as Sentry from '@sentry/browser';
import '@triarc-labs/applink/include';

async function bootstrap() {
  try {
    await extendConfiguration();
    if (environment.production) {
      enableProdMode();
    }

    if (environment.sentry) {
      Sentry.init({
        dsn: environment.sentry || undefined,
        environment: environment.envName || undefined,
        release: `kallisto-v${environment.version}`,
      });
    }
    const m = await import('./app/app.module');
    await platformBrowser().bootstrapModule(m.AppModule);
  } catch (error) {
    console.log(error);
    try {
      const element = document.getElementById('errorContainer') as HTMLElement;
      // Remove class 'hidden' from 'element'
      element.classList.remove('hidden');

      const details = element.querySelector('#error-details');
      if (details) {
        details.innerHTML = error?.message || 'unknown error: ' + JSON.stringify(error);
      }
    } catch (err) {
      // ignore
    }
    if (Sentry) {
      Sentry.captureEvent({
        message: 'app bootstrap failed',
      });
      Sentry.captureException(error);
    }
  } finally {
    document.getElementById('errorContainerTimeout')?.remove();
    document.getElementById('mainLoadingIcon')?.remove();
  }
}
bootstrap();
